import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { useMediaQuery } from '@material-ui/core';
import axios from 'axios';

import bg from '../assets/vbbg.jpg';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexGrow: 1,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '60%',
    marginBottom: theme.spacing(3),
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    opacity: 0, // Sembunyikan gambar secara default
    transition: 'opacity 0.3s ease-in-out', // Tambahkan transisi untuk perubahan opacity
  },
  clickableArea: {
    position: 'absolute',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)', // Center the image within the clickable area
    fontSize: 'clamp(6px, 1.4vw, 16px)', // Use clamp() to adjust font size based on image size
  },
}));


const Simposium = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [link, setLink] = useState(true);
  const isMobile = useMediaQuery('(max-width:1280px)');

  useEffect(() => {
    const loading = async () => {
      if(!props.akun.nama){
        navigate('/login')
      }
    };
    loading();

    const fetchData = async () => {
      try {
        const response = await axios.post('https://quinnstechnology.com/macplam/webservice.php?fungsi=simposium');
        setLink(response.data.link);
        setIsLoaded(true); // Setelah data dimuat, set isLoaded menjadi true
      } catch (error) {
        window.alert(error);
      }
    };
    fetchData();

  }, []);


  return (
    <React.Fragment style={{backgroundColor: 'black'}} >
      <div className={classes.heroContent}style={{paddingTop: 120}} >
        <div className={classes.imageContainer}>
          <div className={classes.clickableArea} style={{top: isMobile ? '45%' :'35%', left: '50%',width: '90%', height: isMobile ? '85%' : '65%', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: isMobile ? 50 : 100}}>
            <ReactPlayer
              url={link}
              width='100%'
              height="100%"
              onClick={() => setOpenModal(true)}
              light // Show the thumbnail instead of loading the player
              style={{opacity: isLoaded ? 1 : 0}}
              controls
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Simposium;
