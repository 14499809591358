import React, {useEffect} from 'react';
import { Container } from '@material-ui/core';
import AOS from 'aos';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  image: {
    width: '100%',
    height: 'auto'
  },
}));

function Freepaper() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.heroContent} style={{paddingTop: 100}} data-aos="fade-right">
        <Container maxWidth="md">
{/* {        <h5 style={{color: 'red', marginBottom: 40}}>Pengumuman: Abstrak dapat dikumpulkan paling lambat bersamaan dengan naskah lengkap <b>15 Agustus 2023</b>. </h5> */}
        <div>
          <h1>MaCPLAM VIII: Free Paper Presentation</h1>
          <p></p>
          <h3>Pedoman Umum Peserta Free Paper Presentation</h3>
          <ol>
            <li>
              Peserta terdaftar sebagai peserta simposium MaCPLAM VIII dan akan diikutkan dalam MaCPLAM Awards
            </li>
            <li>
              Makalah disajikan dalam bentuk Oral Free Paper
            </li>
            <li>
              Waktu untuk presentasi oral 7 menit dan diskusi 5 menit
            </li>
            <li>
              Peserta mengirimkan abstrak dan naskah lengkap sesuai template yang dikirimkan dari panitia
            </li>
            <li>
              Abstrak paling lambat diterima dan naskah lengkap tanggal <b>21 Juni 2024</b>, dikirim ke email <b>macplam8.freepaper@gmail.com</b>
            </li>
            <li>
              Makalah naskah lengkap dijilid 3 rangkap dan diterima oleh panitia pada saat hari H presentasi.
            </li>
            <li>
              Abstrak peserta Free Paper Presentation akan dimasukkan ke dalam Proceeding Ebook MaCPLAM VIII
            </li>
          </ol>
          <h3>Pedoman Umum Penulisan Abstrak</h3>
          <ol>
            <li>
              Abstrak dibuat dalam bahasa Indonesia & bahasa Inggris, maksimal 250 kata (tidak termasuk Judul, Afiliasi/Institusi, dan Kata Kunci)
            </li>
            <li>
              Diketik dengan font Times New Roman, ukuran font 12, jarak 1 spasi, margin 4-4-3-3
            </li>
            <li>
              Judul diketik seluruhnya dengan huruf kapital
            </li>
            <li>
              Abstrak penelitian terdiri atas: Judul penelitian, Nama Peneliti, Asal Institusi, Pendahuluan, Metode, Hasil Penelitian, Simpulan dan Saran, serta Kata Kunci (kata kunci disusun berdasarkan urutan abjad)
            </li>
            <li>
              Abstrak dikirim melalui email: <b>macplam8.freepaper@gmail.com</b> paling lambat tanggal <b>21 Juni 2024</b> dengan judul email <b>ABSTRAK FREE PAPER: Judul naskah</b>. Berikan informasi identitas dan nomor telepon/WhatssApp peserta pada isi email.
            </li>
          </ol>
          <h3>Pedoman Umum Penulisan Naskah Lengkap</h3>
          <ol>
            <li>
              Naskah lengkap dibuat dalam bahasa Indonesia berpedoman pada Ejaan Yang Disempurnakan (EYD) dan berpedoman pada istilah Kedokteran yang baku
            </li>
            <li>
              Diketik dengan font Times New Roman, ukuran font 12, jarak 1.5 spasi, kertas A4, margin 4-4-3-3
            </li>
            <li>
              Judul diketik seluruhnya dengan huruf kapital
            </li>
            <li>
              Jumlah halaman maksimal 10 halaman (tidak termasuk Daftar Pustaka)
            </li>
            <li>
              Naskah lengkap terdiri atas: Judul penelitian, Nama Peneliti, Asal Institusi, Pendahuluan, Metode, Hasil Penelitian, Pembahasan, Simpulan dan Saran, Daftar Pustaka
            </li>
            <li>
            	Daftar Pustaka disusun berdasarkan Vancouver style.
            </li>
            <li>
            	Naskah lengkap dikirim melalui email: <b>macplam8.freepaper@gmail.com</b> paling lambat tanggal <b>21 Juni 2024</b> dengan judul email <b>FULLTEXT FREE PAPER: Judul naskah</b>. Berikan informasi identitas dan nomor telepon/WhatssApp peserta pada isi email.
            </li>
          </ol>
          <h3>Pedoman Umum Penulisan Abstrak Laporan Kasus</h3>
          <ol>
            <li>
              Abstrak dibuat dalam bahasa Indonesia & bahasa Inggris, maksimal 250 kata (tidak termasuk Judul, Afiliasi/Institusi, dan Kata Kunci)
            </li>
            <li>
              Diketik dengan font Times New Roman, ukuran font 12, jarak 1 spasi, margin 4-4-3-3
            </li>
            <li>
              Judul diketik seluruhnya dengan huruf kapital
            </li>
            <li>
              Abstrak terdiri atas: Judul, Nama peneliti, Asal Institusi, Pendahuluan, Kasus, Pembahasan, Simpulan, Kata Kunci (kata kunci disusun berdasarkan urutan abjad).
            </li>
            <li>
              Abstrak dikirim melalui email: <b>macplam8.freepaper@gmail.com</b> paling lambat tanggal <b>21 Juni 2024</b> dengan judul email <b>ABSTRAK FREE PAPER: Judul naskah</b>. Berikan informasi identitas dan nomor telepon/WhatssApp peserta pada isi email.
            </li>
          </ol>
          <h3>Pedoman Umum Penulisan Naskah Lengkap Laporan Kasus</h3>
          <ol>
            <li>
              Naskah lengkap dibuat dalam bahasa Indonesia berpedoman pada Ejaan Yang Disempurnakan (EYD) dan berpedoman pada istilah Kedokteran yang baku
            </li>
            <li>
              Diketik dengan font Times New Roman, ukuran font 12, jarak 1.5 spasi, kertas A4, margin 4-4-3-3
            </li>
            <li>
              Judul diketik seluruhnya dengan huruf kapital
            </li>
            <li>
              Jumlah halaman maksimal 10 halaman (tidak termasuk Daftar Pustaka)
            </li>
            <li>
              Naskah lengkap terdiri atas: Judul, Nama peneliti, Asal Institusi, Pendahuluan, Kasus, Pembahasan, Simpulan, Daftar Pustaka
            </li>
            <li>
              Daftar Pustaka disusun berdasarkan Vancouver style.
            </li>
            <li>
              Naskah lengkap dikirim melalui email: <b>macplam8.freepaper@gmail.com</b> paling lambat tanggal <b>21 Juni 2024</b> dengan judul email <b>LAPORAN KASUS: Judul naskah</b>. Berikan informasi identitas dan nomor telepon/WhatssApp peserta pada isi email.
            </li>
          </ol>
          <div style={{marginTop: 30}}>
            <p>Format Penulisan dan Format Cover dapat didownload pada link berikut:</p>
            <p><a href="https://quinnstechnology.com/macplam/assets/format.pdf" download target="_blank" className="download-link" rel="noopener noreferrer">
              <i className="fa fa-download"></i>
              Format Penulisan
            </a></p>
            <a href="https://quinnstechnology.com/macplam/assets/cover.pdf" download target="_blank" className="download-link" rel="noopener noreferrer">
              <i className="fa fa-download"></i>
              Cover
            </a>
          </div>
          <div style={{marginTop: 30}}>
            <p>Format Penulisan dan Format Cover Laporan Kasus dapat didownload pada link berikut:</p>
            <p><a href="https://quinnstechnology.com/macplam/assets/formatlapsus.pdf" download target="_blank" className="download-link" rel="noopener noreferrer">
              <i className="fa fa-download"></i>
              Format Penulisan Laporan Kasus
            </a></p>
            <a href="https://quinnstechnology.com/macplam/assets/coverlapsus.pdf" download target="_blank" className="download-link" rel="noopener noreferrer">
              <i className="fa fa-download"></i>
              Cover Laporan Kasus
            </a>
          </div>
        </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Freepaper;
