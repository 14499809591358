import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Stepper, Step, StepLabel, Typography, Grid, Button, } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 100
  },
  paper: {
    width: '100%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepper: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  stepLabel: {
    fontWeight: 'bold',
  },
  progressContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressStep: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    marginRight: theme.spacing(2),
  },
  progressStepActive: {
    backgroundColor: '#008CBA',
    color: 'white',
  },
  progressStepNumber: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  progressStepLabel: {
    textAlign: 'center',
  },
}));

function getSteps() {
  return ['Payment', 'Registration Complete'];
}

export default function UserProfile(props) {
  const classes = useStyles();
  const steps = getSteps();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [pesanan, setPesanan] = useState({total: 0});
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  useEffect(() => {
    if (!props.akun.email) {
      navigate('/login');
    }
  }, [props.akun, navigate]);

  useEffect(() => {
    if (!props.akun.email) return
    const loading = async () => {
      try {
        const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
          params: {
            fungsi: 'pesanan',
            email: props.akun.email
          }
        });
        const data = response.data;
        if(data.terbayar == '2'){
          setActiveStep(1)
        }
        setPesanan(data)
      } catch (error) {
        alert(error)
      }
    };
    loading(); // Call the function here
  }, []);

  const konfirmasi = async() => {
    if(loading2 || loading1) return
    setLoading1(true)
    try {
      const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
        params: {
          fungsi: 'konfirmasi',
          email: props.akun.email
        }
      });
      const data = response.data;
      setLoading1(false)
      if (typeof data === 'object') {
        props.setAkun(data)
        setActiveStep(1)
      } else {
        alert("Silahkan menunggu konfirmasi dari panitia (maximal 1x24 jam)")
      }
    } catch (error) {
      alert(error)
      setLoading1(false)
    }
  }

  const batalkan = async() => {
    if(loading2 || loading1) return
    if (window.confirm('Apakah yakin ingin membatalkan registrasi?')) {
      setLoading2(true)
      try {
        const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
          params: {
            fungsi: 'batalkan',
            iduser: props.akun.id,
            idpesanan: pesanan.id
          }
        });
        const data = response.data;
        setLoading2(false)
        alert("Registrasi telah berhasil dibatalkan")
        navigate('/registrasi');
      } catch (error) {
        alert(error)
        setLoading2(false)
      }
    }
  }

  return (
    props.akun.email && <div className={classes.root}>
      <Container maxWidth="md" className={classes.container} data-aos="fade-right">
        <Typography variant="h5" gutterBottom>
          User Profile
        </Typography>
        <Paper className={classes.paper}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" gutterBottom style={{display: 'flex', marginBottom: 20}}>
                Registration Details
              </Typography>

              <Typography variant="subtitle2">
                Name:
              </Typography>
              <Typography variant="h6">
                {props.akun.nama}
              </Typography>
              <Typography variant="subtitle2">
                Email:
              </Typography>
              <Typography variant="h6">
                {props.akun.email}
              </Typography>
              <Typography variant="subtitle2">
                Institution:
              </Typography>
              <Typography variant="h6">
                {props.akun.asal}
              </Typography>
              <Typography variant="subtitle2">
                Phone Number:
              </Typography>
              <Typography variant="h6">
                {props.akun.no_hp}
              </Typography>
              <Typography variant="subtitle2">
                Province:
              </Typography>
              <Typography variant="h6">
                {props.akun.provinsi}
              </Typography>
              <Typography variant="subtitle2">
                City:
              </Typography>
              <Typography variant="h6">
                {props.akun.kota}
              </Typography>
              <Typography variant="subtitle2">
                Profession:
              </Typography>
              <Typography variant="h6">
                {props.akun.profesi}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom style={{display: 'flex', marginBottom: 20}}>Progress</Typography>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 && (
                <div>
                  <Typography variant="h6" className={classes.stepTitle}>
                    Payment
                  </Typography>
                  <Typography variant="subtitle1">
                    Anda telah memesan {pesanan.paket} 8th MaCPLAM 2024. Mohon menyelesaikan pembayaran untuk pesanan Anda:
                  </Typography>
                  <Typography variant="subtitle1"><b>
                    Total : Rp. {parseInt(pesanan.total).toLocaleString('id-ID')},-
                  </b></Typography>
                  <Typography variant="subtitle1"><b>
                    Bank : Bank Syariah Indonesia (BSI)
                  </b></Typography>
                  <Typography variant="subtitle1"><b>
                    No Rek : 7263116873
                  </b></Typography>
                  <Typography variant="subtitle1"><b>
                    Nama : PATOLOGI KLINIK
                  </b></Typography>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button type="submit" variant="contained" color="primary" className={classes.button} style={{margin: 10, marginTop: 20, flex:1, display: 'flex'}} onClick={konfirmasi}>
                    {
                      loading1 ?
                        <CircularProgress style={{color: 'white'}}/>
                        :
                        <Typography variant="h6">
                          KONFIRMASI PEMBAYARAN
                        </Typography>
                    }
                    </Button>
                    <Button type="submit" variant="contained" color="secondary" className={classes.button} style={{margin: 10, marginTop: 20, flex:1, display: 'flex'}} onClick={batalkan}>
                    {
                      loading2 ?
                        <CircularProgress style={{color: 'white'}}/>
                        :
                        <Typography variant="h6">
                          BATALKAN REGISTRASI
                        </Typography>
                    }
                    </Button>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div>
                  <Typography variant="h6" className={classes.stepTitle}>
                    Registrasi Berhasil
                  </Typography>
                  <Typography variant="subtitle1">
                    Anda telah terdaftar {pesanan.paket} MaCPLAM 2023
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          </Paper>
        </Container>
      </div>
  )
};
