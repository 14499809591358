import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import AOS from 'aos';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(10, 0, 6),
    height: window.innerHeight
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  image: {
    width: '100%',
    height: 'auto'
  },
}));

function Freepaper() {
  const classes = useStyles();
  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.heroContent} data-aos="fade-right" style={{paddingTop: 100}}>
        <Container maxWidth="md">
        <div>
          <h5>Hubungi kami:</h5>
          <ol>
            <li>
              <a href="https://wa.me/6281325889925" target="_blank" rel="noopener noreferrer">
                Dr. Ucy Nadjmiyah, Sp.PK (081325889925)
              </a>
            </li>
            <li>
              <a href="https://wa.me/6281355021604" target="_blank" rel="noopener noreferrer">
                Dr. Rizki Syahrifa (081355021604)
              </a>
            </li>
          </ol>
        </div>
        <div style={{marginTop: 100}}>
          <h5>Pemesanan Kamar di Hotel Claro Kendari</h5>
            <p> </p>

            <a href="https://forms.gle/ZBUMRAtKVUwoGcrQ9" target="_blank" rel="noopener noreferrer">
              ISI FORM DISINI
            </a>
            <p> </p>
            <p>atau</p>
            <a href="https://wa.me/6282213127434" target="_blank" rel="noopener noreferrer">
              WA: dr. Erik sam (082213127434)
            </a>
        </div>
        <div style={{marginTop: 100}}>
          <h5>Paket Wisata</h5>
            <p> </p>
            <a href="https://wa.me/6282293249735" target="_blank" rel="noopener noreferrer">
              WA: dr. Irman Karim (082293249735)
            </a>
        </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Freepaper;
