import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Typography } from '@material-ui/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    marginTop: 50
  },
  title: {
    marginBottom: theme.spacing(4),
    color: 'black',
    fontWeight: 'bold',
  },
  button: {
    margin: theme.spacing(2),
    borderColor: 'black',
    color: 'black',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}));

export default function DownloadPage(props) {
  const classes = useStyles();
  const [data, setData] = useState({id: 1, workshop: '', simposium: ''});
  const navigate = useNavigate();
  useEffect(() => {
    const loading = async () => {
      if(!props.akun.nama){
        navigate('/login')
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.post('https://quinnstechnology.com/macplam/webservice.php?fungsi=listmateri');
        setData(response.data);
      } catch (error) {
        window.alert(error);
      }
    };

    loading();
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Container>
        <Typography variant="h4" className={classes.title}>
          Download Materi
        </Typography>
        {
          data.workshop == "" && data.simposium == "" && <Typography variant="p" className={classes.title}>
            Materi belum tersedia
          </Typography>
        }
        {props.akun.paket && props.akun.paket.includes("Simposium") && data.simposium != "" && (
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => window.open(data.simposium, '_blank')}
          >
            Download Materi Simposium
          </Button>
        )}
        {props.akun.paket && props.akun.paket.includes("Workshop") && data.workshop != "" && (
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => window.open(data.workshop, '_blank')}
          >
            Download Materi Workshop
          </Button>
        )}

      </Container>
    </div>
  );
}
