import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';

import Price from '../components/Price';
import Dropdown from '../components/Dropdown';
import '../components/style.css';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    flexDirection: 'row'
  },
  form: {
    backgroundColor: 'white',
    padding: theme.spacing(5),
    borderRadius: '5px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '36px',
    marginBottom: theme.spacing(4)
  },
  input: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: '#008CBA',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0077A5'
    }
  },
}));


function Registrasi(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [kategori, setKategori] = useState('Workshop 1 dan Simposium');
  const [profesi, setProfesi] = useState('Dokter Spesialis');
  const [fullName, setFullName] = useState('');
  const [institution, setInstitution] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [total, setTotal] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loading, setLoading] = useState(false);

  const now = new Date();
  const earlyBirdDeadline = new Date("2024-05-06");

  const isEarlyBird = now < earlyBirdDeadline;

  const isValidEmail = (em) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(em);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(loading) return
    if(password !== repassword)return alert('Password tidak sama')
    if(isValidEmail(email) == false) return alert('Email tidak valid')
    setLoading(true);

    let modifiedKategori = kategori;
    if (modifiedKategori.includes("Workshop 1")) {
      modifiedKategori = modifiedKategori.replace("Workshop 1", "Workshop Hospital Blood Bank Set Up");
    }
    if (modifiedKategori.includes("Workshop 2")) {
      modifiedKategori = modifiedKategori.replace("Workshop 2", "Workshop Peripheral Blood Smear Examination");
    }
  
    try {
      const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
        params: {
          fungsi: 'register',
          email: email,
          nama: fullName,
          profesi: profesi,
          asal: institution,
          no_hp: phoneNumber,
          provinsi: province,
          kota: city,
          password: password,
          paket: modifiedKategori,  // Use the modified kategori here
          total: total
        }
      });
      const data = response.data;
      if(Number.isInteger(data)){
        props.setAkun({id:data,nama:fullName,profesi:profesi,asal:institution,no_hp:phoneNumber,provinsi:province,kota:city,email:email,password:password,paket:modifiedKategori})
        navigate('/profile');
      }

      if(data == "sudah ada"){
        const response = await axios.get(`https://quinnstechnology.com/macplam/webservice.php`, {
          params: {
            fungsi: 'login',
            email: email,
          }
        });
        const data = response.data;
        props.setAkun({id:data,nama:fullName,profesi:profesi,asal:institution,no_hp:phoneNumber,provinsi:province,kota:city,email:email,password:password,paket:kategori})
        navigate('/profile');
        alert("Email sudah terdaftar")
      }
      // handle successful registration
    } catch (error) {
      alert(error)
    } finally {
    setLoading(false);
    }

  };

  const kategoriChange = (e) => {
    setKategori(e.target.value)
  }

  const profesiChange = (e) => {
    setProfesi(e.target.value)
  }

  useEffect(() => {
    const priceChange = () => {
      let harga = 0;

      if (kategori === "Workshop 1 dan Simposium") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 2250000 : 2500000;
        } else {
          harga = isEarlyBird ? 1000000 : 1500000;
        }
      } else if (kategori === "Workshop 2 dan Simposium") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 2250000 : 2500000;
        } else {
          harga = isEarlyBird ? 1000000 : 1500000;
        }
      } else if (kategori === "Workshop 1") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 1500000 : 1750000;
        } else {
          harga = isEarlyBird ? 750000 : 1000000;
        }
      } else if (kategori === "Workshop 2") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 1500000 : 1750000;
        } else {
          harga = isEarlyBird ? 750000 : 1000000;
        }
      } else if (kategori === "Simposium") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 1250000 : 1500000;
        } else {
          harga = isEarlyBird ? 500000 : 750000;
        }
      // } else if (kategori === "Simposium (ONLINE)") {
      //   if (profesi === "Dokter Spesialis") {
      //     harga = isEarlyBird ? 350000 : 500000;
      //   } else {
      //     harga = isEarlyBird ? 250000 : 350000;
      //   }
      } else if (kategori === "Publikasi Free Paper dan Simposium") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 1250000 : 1500000;
        } else {
          harga = isEarlyBird ? 500000 : 750000;
        }
      } else if (kategori === "Workshop 1, Simposium dan Publikasi Free Paper") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 2250000 : 2500000;
        } else {
          harga = isEarlyBird ? 1000000 : 1500000;
        }
      } else if (kategori === "Workshop 2, Simposium dan Publikasi Free Paper") {
        if (profesi === "Dokter Spesialis") {
          harga = isEarlyBird ? 2250000 : 2500000;
        } else {
          harga = isEarlyBird ? 1000000 : 1500000;
        }
      }



      setTotal(harga);
    };

    priceChange();
  }, [kategori, profesi, isEarlyBird, []]);



  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.heroContent} style={{display: 'flex', alignItems: 'flex-start', flexDirection: isSmallScreen ? 'column' : 'row', paddingTop: 100}} data-aos="fade-right">
          <div style={{ width: '100%', marginLeft: 20, marginBottom: 30 }}>
            <Price />
          </div>
        <Container maxWidth="md">
          <form onSubmit={handleSubmit} className={classes.form}>
            <h2 className={classes.heading}>Registration Form</h2>
            <p style={{ textAlign: 'left', width: '100%', fontStyle: 'italic' }}>Workshop 1: Hospital Blood Bank Set Up</p>
            <p style={{ textAlign: 'left', width: '100%', fontStyle: 'italic' }}>Workshop 2: Integrating Routine Blood Analysis with Peripheral Blood Smear Examination: A Comprehensive Approach</p>
            <p></p>
           <Dropdown
              label="Kategori Acara"
              item={["Workshop 1 dan Simposium", "Workshop 2 dan Simposium", "Workshop 1", "Workshop 2", "Simposium", "Publikasi Free Paper dan Simposium", "Workshop 1, Simposium dan Publikasi Free Paper", "Workshop 2, Simposium dan Publikasi Free Paper"]}
              value={kategori}
              onChange={kategoriChange}
            />
            <Dropdown
              label="Profesi"
              item={["Dokter Spesialis", "Dokter Umum", "Bukan Dokter"]}
              value={profesi}
              onChange={profesiChange}
            />
            <TextField
              id="full-name-input"
              label="Nama Lengkap Dengan Gelar"
              variant="outlined"
              className={classes.input}
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
              required
            />
            <TextField
              id="institution-input"
              label="Asal Institusi"
              variant="outlined"
              className={classes.input}
              value={institution}
              onChange={(event) => setInstitution(event.target.value)}
              required
            />
            <TextField
              id="phone-number-input"
              label="Nomor Handphone"
              variant="outlined"
              className={classes.input}
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              required
            />
            <TextField
              id="province-input"
              label="Provinsi"
              variant="outlined"
              className={classes.input}
              value={province}
              onChange={(event) => setProvince(event.target.value)}
              required
            />
            <TextField
              id="city-input"
              label="Kota"
              variant="outlined"
              className={classes.input}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              required
            />
            <TextField
              id="email-input"
              label="Email"
              variant="outlined"
              className={classes.input}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <TextField
              id="password-input"
              label="Buat Password"
              type="password"
              variant="outlined"
              className={classes.input}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
            <TextField
              id="repassword-input"
              label="Ulangi Password"
              type="password"
              variant="outlined"
              className={classes.input}
              value={repassword}
              onChange={(event) => setRePassword(event.target.value)}
              required
            />
            <h3>Total: Rp. {total.toLocaleString('id-ID')},-</h3>

            <Button type="submit" variant="contained" color="primary" className={classes.button}>
              {
                loading ?
                <CircularProgress style={{color: 'white'}}/>
                :
                <Typography variant="h6">
                  Register
                </Typography>
              }
            </Button>
          </form>
      </Container>
    </div>
    </React.Fragment>
  );
  }

export default Registrasi;
