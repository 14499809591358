import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
}));

function DropDown(props) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="category-label">{props.label}</InputLabel>
      <Select
        labelId="category-label"
        id="category-select"
        value={props.value}
        onChange={props.onChange}
        label="Category"
        required
      >
        {
          props.item.map((it, idx) => (
            <MenuItem value={it} key={idx}>{it}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default DropDown;
