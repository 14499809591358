import React, { useState } from 'react';
import useStyles from '../styles/navbarStyles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';

function Navbar(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation();

  const sideList = (props) => (
    <div className={classes.list} >
      <List>
        {
          // props.akun.nama && <Typography variant="body1" >
          // <Link onClick={toggleDrawer} to="/profile" className={classes.link + " " + (location.pathname === '/profile' ? classes.activeLink : '')} style={{ display: 'flex', color: '#50A5DC', marginTop: 20, marginBottom: 20}}>
          //   <ListItem button onClick={toggleDrawer}>
          //     <ListItemText primary={props.akun.nama } />
          //   </ListItem>

          // </Link>
          // </Typography>
        }
        {
          !props.akun.nama && <Typography variant="body1" >
            <Link onClick={toggleDrawer} to="/login" className={classes.link + " " + (location.pathname === '/login' ? classes.activeLink : '')} style={{ display: 'flex', color: '#50A5DC', marginTop: 20, marginBottom: 20}}>
              <ListItem button onClick={toggleDrawer}>
                <ListItemText primary="Login" />
              </ListItem>

            </Link>
            </Typography>
        }

        {
          !props.akun.nama && <Link to="/" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
            <ListItem button onClick={toggleDrawer}>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        }
        {
          // !props.akun.nama && <Link to="/jadwal" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
          //   <ListItem button onClick={toggleDrawer}>
          //     <ListItemText primary="Jadwal" />
          //   </ListItem>
          // </Link>
        }
        {
          // !props.akun.nama && <Link to="/freepaper" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
          //   <ListItem button onClick={toggleDrawer}>
          //     <ListItemText primary="FreePaper" />
          //   </ListItem>
          // </Link>
        }
        {
          // !props.akun.nama && <Link to="/registrasi" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
          //   <ListItem button onClick={toggleDrawer}>
          //     <ListItemText primary="Registrasi" />
          //   </ListItem>
          // </Link>
        }
        {
          // !props.akun.nama && <Link to="/contact" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
          //   <ListItem button onClick={toggleDrawer}>
          //     <ListItemText primary="Contact & Akomodasi" />
          //   </ListItem>
          // </Link>
        }
        {/* {
          props.akun.nama && <Link to="/simposium" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
            <ListItem button onClick={toggleDrawer}>
              <ListItemText primary="Simposium" />
            </ListItem>
          </Link>
        } */}
        {/* {
          props.akun.nama && <Link to="/virtual" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
            <ListItem button onClick={toggleDrawer}>
              <ListItemText primary="Virtual Booth" />
            </ListItem>
          </Link>
        } */}
        {
          // props.akun.nama && <Link to="/jadwal" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
          //   <ListItem button onClick={toggleDrawer}>
          //     <ListItemText primary="Jadwal" />
          //   </ListItem>
          // </Link>
        }
        {/* {
          props.akun.nama && <Link to="/materi" className={classes.link + " " + (location.pathname === '/' ? classes.activeLink : '')}>
            <ListItem button onClick={toggleDrawer}>
              <ListItemText primary="Materi" />
            </ListItem>
          </Link>
        } */}
      </List>
    </div>
  );

  return (
      <AppBar position="sticky" className={classes.appbar} style={{paddingLeft:isMobile ? 0 : '10%', paddingRight: isMobile ? 0 :'10%', backgroundColor: 'white'}} >

        <Toolbar>
          <Hidden smUp>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer} className={classes.menuButton}>
              <MenuIcon style={{color: 'black'}}/>
            </IconButton>
          </Hidden>
          <Link to="/" className={classes.link} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1}}>
            <img src={require("../assets/logo.png")} className={classes.logo} alt=""/>
            <Typography variant="h6" className={classes.title}>
              MaCPLAM
            </Typography>
          </Link>
          <Hidden xsDown>
            {
              !props.akun.nama && <Typography variant="body1" className={classes.linkText}>
                <Link to="/" className={classes.link +  " " + (location.pathname === '/' ? classes.activeLink : '')}>
                  Home
                </Link>
              </Typography>
            }
            {
              // !props.akun.nama && <Typography variant="body1" className={classes.linkText }>
              //   <Link to="/jadwal" className={classes.link + " " + (location.pathname === '/jadwal' ? classes.activeLink : '')}>
              //     Jadwal
              //   </Link>
              // </Typography>
            }
            {
              // !props.akun.nama && <Typography variant="body1" className={classes.linkText }>
              //   <Link to="/freepaper" className={classes.link + " " + (location.pathname === '/freepaper' ? classes.activeLink : '')}>
              //     Free Paper
              //   </Link>
              // </Typography>
            }

            {
              // !props.akun.nama && <Typography variant="body1" className={classes.linkText }>
              //   <Link to="/registrasi" className={classes.link + " " + (location.pathname === '/registrasi' ? classes.activeLink : '')}>
              //     Registrasi
              //   </Link>
              // </Typography>
            }
            {
              // !props.akun.nama && <Typography variant="body1" className={classes.linkText }>
              //   <Link to="/contact" className={classes.link + " " + (location.pathname === '/contact' ? classes.activeLink : '')}>
              //     Contact & Akomodasi
              //   </Link>
              // </Typography>
            }
            {/* {
              props.akun.nama && <Typography variant="body1" className={classes.linkText }>
                <Link to="/simposium" className={classes.link + " " + (location.pathname === '/simposium' ? classes.activeLink : '')}>
                  Simposium
                </Link>
              </Typography>
            } */}
            {/* {
              props.akun.nama && <Typography variant="body1" className={classes.linkText }>
                <Link to="/virtual" className={classes.link + " " + (location.pathname === '/virtual' ? classes.activeLink : '')}>
                  Virtual Booth
                </Link>
              </Typography>
            } */}
            {
              // props.akun.nama && <Typography variant="body1" className={classes.linkText }>
              //   <Link to="/jadwal" className={classes.link + " " + (location.pathname === '/jadwal' ? classes.activeLink : '')}>
              //     Jadwal
              //   </Link>
              // </Typography>
            }
            {/* {
              props.akun.nama && <Typography variant="body1" className={classes.linkText }>
                <Link to="/materi" className={classes.link + " " + (location.pathname === '/materi' ? classes.activeLink : '')}>
                  Materi
                </Link>
              </Typography>
            } */}
            {
              // props.akun.nama && <Typography variant="body1" className={classes.linkText + " " + (location.pathname === '/profile' ? classes.activeLink : '')} style={{ display: 'flex', alignItems: 'center' }}>
              //   <Link to="/profile" className={classes.link} style={{ display: 'flex', color: '#50A5DC', alignItems: 'center', justifyContent: 'center' }}>
              //     <img src={require('../assets/profile.png')} alt="" style={{ marginRight: '8px', width: 20, height: 20 }} />
              //     {props.akun.nama }
              //   </Link>
              // </Typography>
            }
            {
              // !props.akun.nama && <Typography variant="body1" className={classes.linkText + " " + (location.pathname === '/login' ? classes.activeLink : '')}>
              //   <Link to="/login" className={classes.link} style={{ display: 'flex', color: '#50A5DC', alignItems: 'center', justifyContent: 'center' }}>
              //       Login
              //     </Link>
              //   </Typography>
            }
          </Hidden>
        </Toolbar>
        <Hidden smUp>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
            {sideList(props)}
          </Drawer>
        </Hidden>

      </AppBar>
  );
}

export default Navbar;
