import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  TextField,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '800px',
    padding: theme.spacing(4),
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: '#008CBA',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0077A5',
    },
  },
  link: {
    marginTop: theme.spacing(2),
  },
}));

function Login(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidEmail = (em) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(em);
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(isValidEmail(email) == false) return setErr("Email tidak valid")
    if(loading) return
    setLoading(true)
    try {
      const response = await axios.post('https://quinnstechnology.com/macplam/webservice.php?fungsi=forgetpassword&email='+email)
      if(response.data == "Success"){
        alert("Password telah dikirimkan ke email anda")
        navigate('/login');
      } else {
        setErr("Email tidak terdaftar")
      }
      setLoading(false)
    } catch (error) {
      window.alert(error);
      setLoading(false)
    }
  };

  return (
    <Container maxWidth="xs" className={classes.container}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Typography variant="h5" component="h1" gutterBottom>
          Recover Password
        </Typography>
        <TextField
          id="email-input"
          label="Email"
          type="email"
          variant="outlined"
          className={classes.input}
          value={email}
          onChange={(event) => {
            setErr('')
            setEmail(event.target.value)
          }}
          required
        />
        <Typography variant="subtitle1" align="left" color="textSecondary" style={{display: 'flex'}}>
          {err}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          {
            loading ?
              <CircularProgress style={{color: 'white'}}/>
              :
              <Typography variant="h6">
                Email Password
              </Typography>
          }
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => navigate('/login')}
        >
          <Typography variant="h6">
            Batal
          </Typography>
        </Button>
      </form>
    </Container>
  );
}

export default Login;
