import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: theme.palette.secondary.light,
      textDecoration: 'underline',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  logo: {
    height: 40,
    width: 'auto',
    marginRight: 10
  },
  appbar: {
    backgroundColor: 'white',
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    color: '#2F353B',
    display: 'flex',
    justifyContent: 'center'
  },
  link: {
    color: '#2F353B',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    marginRight: 20
  },
  list: {
    width: 250,
    backgroundColor: "white",
    height: "100%",
  },
  activeLink: {
    color: 'black',
    fontWeight: 'bold',
  },
  cardContent: {
    justifyContent: 'center',
    alignItems: 'center'
  },


}));

export default useStyles;
