import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
});

function PriceTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="price table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Workshop</TableCell>
            <TableCell align="center">Simposium (Offline)</TableCell>
            <TableCell align="center">Workshop + Simposium (Offline)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}><b>Early Bird (Sebelum 6 Mei 2024)</b></TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Spesialis</TableCell>
            <TableCell align="center">1.500.000</TableCell>
            <TableCell align="center">1.250.000</TableCell>
            <TableCell align="center">2.250.000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Non-Spesialis</TableCell>
            <TableCell align="center">750.000</TableCell>
            <TableCell align="center">500.000</TableCell>
            <TableCell align="center">1.000.000</TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={5}><b>Setelah 6 Mei 2024</b></TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Spesialis</TableCell>
            <TableCell align="center">1.750.000</TableCell>
            <TableCell align="center">1.500.000</TableCell>
            <TableCell align="center">2.500.000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Non-Spesialis</TableCell>
            <TableCell align="center">1.000.000</TableCell>
            <TableCell align="center">750.000</TableCell>
            <TableCell align="center">1.500.000</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PriceTable;
