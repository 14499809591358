import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Modal, Backdrop, IconButton, Button, Hidden, useMediaQuery } from '@material-ui/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import booth1 from '../assets/booth11.png';
import booth2 from '../assets/booth22.png';
import booth3 from '../assets/booth33.png';
import bg from '../assets/vbbg.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexGrow: 1,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '60%',
    marginBottom: theme.spacing(3),
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    transition: 'opacity 0.3s ease-in-out', // Tambahkan transisi untuk perubahan opacity
  },
  clickableArea: {
    position: 'absolute',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)', // Center the image within the clickable area
    fontSize: 'clamp(6px, 1.4vw, 16px)', // Use clamp() to adjust font size based on image size
    // animation: `$pulsate 1.5s infinite`,
    overflow: 'hidden', // Ini akan memastikan gambar tidak melampaui batas div
  },
  pt: {
    position: 'absolute',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)', // Center the image within the clickable area
    fontSize: 'clamp(8px, 1.4vw, 16px)', // Use clamp() to adjust font size based on image size
    // border: '3px solid blue', // Add border
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  imageModalContent: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '90vh',
    overflow: 'auto',
  },
  leftDiv: {
    position: 'absolute',
    left: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10),
    width: 'calc((100% - 960px))', // 960px is the default width for 'md' maxWidth in Material-UI
  },
  rightDiv: {
    position: 'absolute',
    right: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10),
    width: 'calc((100% - 960px))', // 960px is the default width for 'md' maxWidth in Material-UI
  },
  button: {
    margin: theme.spacing(1),
    borderColor: 'white',
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 30,
    paddingLeft: 50,
    paddingRight: 50,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap', // Ini memungkinkan item untuk beralih ke baris berikutnya jika tidak ada ruang
    justifyContent: 'center', // Pusatkan item di tengah
    alignItems: 'center',
    gap: theme.spacing(0.5), // Jarak antara tombol
    marginTop: theme.spacing(1), // Anda dapat menyesuaikan ini sesuai kebutuhan
  },
  logo: {
    position: 'absolute',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)', // Center the image within the clickable area
    fontSize: 'clamp(6px, 1.4vw, 16px)',
  },
  pulsate: {
    animation: `$pulsate 1.5s infinite`,
  },
  "@keyframes pulsate": {
    "0%": {
      transform: "translate(-50%, -50%) scale(1)",
    },
    "50%": {
      transform: "translate(-50%, -50%) scale(1.05)",
    },
    "100%": {
      transform: "translate(-50%, -50%) scale(1)",
    },
  },
  arrowButton: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparan
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    zIndex: 10
  },

}));

export default function ImagePage() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openImage1Modal, setOpenImage1Modal] = useState(false);
  const [openImage2Modal, setOpenImage2Modal] = useState(false);
  const [openImage3Modal, setOpenImage3Modal] = useState(false);
  const [openImage4Modal, setOpenImage4Modal] = useState(false);
  const isMobile = useMediaQuery('(max-width:1280px)');
  const location = useLocation();
  const it = location.state;
  const [startX, setStartX] = useState(0);
  const [data, setData] = useState({gambar1: '', gambar2: '', logo: ''});
  const [scrollLeft, setScrollLeft] = useState(0);
  const [zoomOut, setZoomOut] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [boothActive, setBoothActive] = useState();
  const [bgLoaded, setBgLoaded] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.post('https://quinnstechnology.com/macplam/webservice.php?fungsi=booth&id='+id);
        if(response !== undefined) setData(response.data[0]);
        if(response.data[0].booth == 'booth1.png') setBoothActive(booth1)
        if(response.data[0].booth == 'booth2.png') setBoothActive(booth2)
        if(response.data[0].booth == 'booth3.png') setBoothActive(booth3)
      } catch (error) {
        window.alert(error);
      }
    };
    fetchData();
  }, []);


  const handleBackClick = (booth) => {
    setZoomOut(!zoomOut)
    setTimeout(() => {
        navigate('/virtual');
    }, 2000);
  };

  const img1Style = {
    "booth1.png": {top: '72%', left: '22%', width: 'auto', height: '42%'},
    "booth2.png": {top: '52%', left: '15%', width: 'auto', height: '42%'},
    "booth3.png": {top: '56%', left: '52%', width: '25%', height: '32%'}, // Ubah posisi dan ukuran ini
  }

  const videoStyle ={
    "booth1.png": {top: '62%', left: '45%', width: '20%', height: '30%',display: 'flex',justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', borderRadius: 5},
    "booth2.png": {top: '38%', left: '50%', width: '16.6%', height: '26%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', borderRadius: 5},
    "booth3.png": {top: '52%', left: '63%', width: '16.6%', height: '26%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', borderRadius: 5},
  }

  const img2Style = {
    "booth1.png": {top: '62%', left: '68%',width: '22%', maxHeight: '31%'},
    "booth2.png": {top: '44%', left: '74.3%', width: '22%', height: '31%'},
    "booth3.png": {top: '56%', left: '90%', width: '24%', height: '32%'},
  }

  const imgContStyle = {
    "booth1.png": {marginTop: isMobile ? 50 : 0},
    "booth2.png": {marginTop: isMobile ? 50 : 0},
    "booth2.png": {marginTop: isMobile ? 50 : 0},
  }

  const compStyle = {
    "booth1.png": {top: '85%', left: '74%', width: '30%', height: '15%', justifyContent: 'center', alignItems: 'center', display: 'flex', alignSelf: 'center', backgroundColor: 'white'},
    "booth2.png": {top: '65%', left: '49%', width: '30%', height: '15%', justifyContent: 'center', alignItems: 'center', display: 'flex', alignSelf: 'center', backgroundColor: 'white'},
    "booth3.png": {top: '65%', left: '24%', width: '18%', height: '9%', justifyContent: 'center', alignItems: 'center', display: 'flex', alignSelf: 'center', backgroundColor: 'white'},
  }

  return (
    <div className={classes.root}>
      <img src={bg} style={{display: 'none'}} onLoad={() => setBgLoaded(true)} alt="Background Loader" />

      <Container maxWidth="md">
        <div className={classes.buttonContainer}>
          {
            data.deskripsi && <Button variant="outlined" className={classes.button} onClick={() => setOpenImage3Modal(true)}>Profil</Button>
          }
          {
            data.kontak && <Button variant="outlined" className={classes.button} onClick={() => setOpenImage4Modal(true)}>Kontak</Button>
          }
          {
            data.web && <Button variant="outlined" className={classes.button} onClick={() => window.open(data.web, '_blank')}>Website</Button>
          }
          {
            data.quiz && <Button variant="outlined" className={classes.button} onClick={() => window.open(data.quiz, '_blank')}>Quiz</Button>
          }
          {
            data.katalog && <Button variant="outlined"
              className={classes.button}
              onClick={() => {
                if (data.katalog.startsWith("https://")) {
                  window.open(data.katalog, '_blank');
                } else {
                  window.open('https://quinnstechnology.com/macplam/booth/' + data.katalog, '_blank');
                }
              }}
              >Katalog</Button>
          }
          {
            data.games && <Button variant="outlined" className={classes.button} onClick={() => window.open(data.games, '_blank')}>Games</Button>
          }
        </div>

        <div className={classes.imageContainer} style={{...imgContStyle[data.booth], transform: zoomOut ? 'scale(0.5)' : '', transition: 'transform 5s, opacity 0.5s'}}>
        <IconButton className={classes.arrowButton} onClick={() => handleBackClick()} style={{top: '10%', left: isMobile ? '0%' :'-5%'}}>
          <ArrowBackIosIcon />
        </IconButton>
          <img
            src={boothActive}
            alt=""
            className={classes.image}
            onLoad={()=> setVisible4(true)}
          />
          <div className={`${classes.clickableArea} ${visible1 ? classes.pulsate : ""}`} onClick={() => setOpenImage1Modal(true)} style={img1Style[data.booth]}>
            <img alt="" src={data.gambar1 ? "https://quinnstechnology.com/macplam/booth/"+data.gambar1 : ''} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} onLoad={() => setVisible1(true)} />
          </div>
          <div className={classes.clickableArea} style={videoStyle[data.booth]}>
            <ReactPlayer
              url={data.video}
              width='100%'
              height='auto'
              onClick={() => setOpenModal(true)}
              light // Show the thumbnail instead of loading the player
              controls
            />
          </div>
          <div className={`${classes.clickableArea} ${visible2 ? classes.pulsate : ""}`} onClick={() => setOpenImage2Modal(true)} style={img2Style[data.booth]}>
            <img alt="" src={data.gambar2 ? "https://quinnstechnology.com/macplam/booth/"+data.gambar2 : ''}  style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} onLoad={() => setVisible2(true)} />
          </div>
          <div className={classes.logo} onClick={() => {window.open(data.web, '_blank')}} style={compStyle[data.booth]} >
            <img alt="" src={data.logo ? "https://quinnstechnology.com/macplam/booth/"+data.logo : ''} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}} onLoad={() => setVisible3(true)}/>
          </div>
        </div>
      </Container>


      <Modal
        className={classes.modal}
        open={openModal}
        onClose={()=> setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.modalContent}>
          <IconButton className={classes.closeButton} onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
          <ReactPlayer
            url={data.video}
            width='90vw'
            height='90vh'
            playing
            controls
          />
        </div>
      </Modal>

      <Modal
        className={classes.modal}
        open={openImage1Modal}
        onClose={() => setOpenImage1Modal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.imageModalContent}>
          <IconButton className={classes.closeButton} onClick={() => setOpenImage1Modal(false)}>
            <CloseIcon />
          </IconButton>
          <img alt="" src={data.gambar1 ? "https://quinnstechnology.com/macplam/booth/"+data.gambar1 : ''} style={{objectFit: 'contain', width: '100%', maxHeight: '90vh'}} />
        </div>
      </Modal>

      <Modal
        className={classes.modal}
        open={openImage2Modal}
        onClose={() => setOpenImage2Modal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.imageModalContent}>
          <IconButton className={classes.closeButton} onClick={() => setOpenImage2Modal(false)}>
            <CloseIcon />
          </IconButton>
          <img alt="" src={data.gambar2 ? "https://quinnstechnology.com/macplam/booth/"+data.gambar2 : ''} style={{objectFit: 'contain', width: '100%', maxHeight: '90vh'}} />
        </div>
      </Modal>

      <Modal
        className={classes.modal}
        open={openImage3Modal}
        onClose={() => setOpenImage3Modal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.imageModalContent}>
          <IconButton className={classes.closeButton} onClick={() => setOpenImage3Modal(false)}>
            <CloseIcon />
          </IconButton>
          <div style={{width: '100%', padding: 20, whiteSpace: 'pre-line'}}>
            <h1>{data.pt}</h1>
            <p>{data.deskripsi}</p>
          </div>
        </div>
      </Modal>

      <Modal
        className={classes.modal}
        open={openImage4Modal}
        onClose={() => setOpenImage4Modal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.imageModalContent}>
          <IconButton className={classes.closeButton} onClick={() => setOpenImage4Modal(false)}>
            <CloseIcon />
          </IconButton>
          <div style={{width: '100%', padding: 20, whiteSpace: 'pre-line'}}>
            <h1>Kontak:</h1>
            <p>{data.kontak}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
