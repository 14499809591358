import React, { useEffect } from 'react';
import ScheduleTable from '../components/ScheduleTable';
import { Container } from '@material-ui/core';
import AOS from 'aos';

import { makeStyles } from '@material-ui/core/styles';

const hari1 = [
  { time: 'Workshop (08.00 - 12.30)', event: '', description: 'Workshop 1 Hospital Blood Bank Set Up', speaker: '', merge: true, mergeCount: 14, skip: false, details: "Kamis, 4 Juli 2024", combineTimeEvent: true, combineDescSpeaker: true},
  { time: '07.30-08.00', event: 'REGISTRASI', description: '', merge: false, skip: true},
  { time: '08.00-08.05', event: 'PEMBUKAAN', description: '', speaker: 'Moderator :\n dr.Hilma Yuniar Thamrin, M.Kes, Sp.PK, FISQua', merge: false, mergeCount: 0, skip: true },
  { time: '08.05-08.20', event: 'PRE-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.20-08.50', event: 'MATERI 1', description: 'BASIC MANAGEMENT OF HOSPITAL BLOOD BANK', speaker: 'DR. Dr. Teguh Triyono, M.Kes, Sp.PK, Subsp.B.D.K.T.(K)', merge: false, mergeCount: 0, skip: true },
  { time: '08.50-09.20', event: 'MATERI 2', description: 'STRATEGIES FOR HANDLING PRETRANSFUSION TEST OUTCOMES IN HOSPITAL BLOOD BANKS', speaker: 'Dr. Raehana Samad, M.Kes, Sp.PK, Subsp.B.D.K.T.(K)', merge: false, mergeCount: 0, skip: true },
  { time: '09.20-09.40', event: 'DISKUSI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.40-09.55', event: 'COFFEE BREAK', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.55-11.25', event: 'CASE STUDY & PRACTICE', description: '', speaker: 'Fasilitator: DR. Dr. Teguh Triyono, M.Kes, Sp.PK, Subsp.B.D.K.T.(K); Dr. Raehana Samad, M.Kes, Sp.PK, Subsp.B.D.K.T.(K); dr.Hilma Yuniar Thamrin, M.Kes, Sp.PK, FISQua', merge: false, mergeCount: 0, skip: true },
  { time: '11.25-11.45', event: 'PRESENTASE PRODUK', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '11.45-13.00', event: 'ISHOMA', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '13.00-13.15', event: 'POST-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '13.15-13.25', event: 'DISKUSI POST-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '13.25-13.35', event: 'PENUTUPAN', description: '', merge: false, mergeCount: 0, skip: true },
]

const hari2 = [
  { time: 'Workshop (08.00 - 12.30)', event: '', description: 'Integrating Routine Blood Analysis with Peripheral Blood Smear Examination: A Comprehensive Approach', speaker: '', merge: true, mergeCount: 14, skip: false, details: "Kamis, 4 Juli 2024", combineTimeEvent: true, combineDescSpeaker: true},
  { time: '07.30-08.00', event: 'REGISTRASI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.00-08.05', event: 'PEMBUKAAN', description: '', speaker: 'Moderator :\nDr. Ingrid A Hutagalung, M.Kes, Sp.PK, Subsp.H.K.(K)', merge: false, mergeCount: 0, skip: true },
  { time: '08.05-08.20', event: 'PRE-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.20-08.50', event: 'MATERI 1', description: 'BASIC IN PERIPHERAL BLOOD SMEAR AND HEMATOLOGY ANALYZER EXAMINATION', speaker: 'Dr. Agus Alim Abdullah, Sp.PK, Subsp.H.K.(K)', merge: false, mergeCount: 0, skip: true },
  { time: '08.50-09.20', event: 'MATERI 2', description: 'INTEGRATION BETWEEN BLOOD ANALYSIS AND BLOOD SMEAR', speaker: 'Prof. Dr. Mansyur Arif, Ph.D, Sp.PK, Subsp.H.K.(K), M.Kes', merge: false, mergeCount: 0, skip: true },
  { time: '09.20-09.40', event: 'DISKUSI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.40-09.55', event: 'COFFEE BREAK', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.55-11.25', event: 'CASE STUDY & PRACTICE', description: '', speaker: 'Fasilitator:\nDr. Agus Alim Abdullah, Sp.PK, Subsp.H.K.(K); Prof. Dr. Mansyur Arif, Ph.D, Sp.PK, Subsp.H.K.(K), M.Kes; Dr. Ingrid A Hutagalung, M.Kes, Sp.PK, Subsp.H.K.(K)', merge: false, mergeCount: 0, skip: true },
  { time: '11.25-11.45', event: 'PRESENTASE PRODUK', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '11.45-13.00', event: 'ISHOMA', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '13.00-13.15', event: 'POST-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '13.15-13.25', event: 'DISKUSI POST-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '13.25-13.35', event: 'PENUTUPAN', description: '', merge: false, mergeCount: 0, skip: true },
]

const hari3 = [
  { details: "Kamis, 4 Juli 2024", time: 'FREE PAPER (14.00-16.00)', event: '', description: '', speaker: '', color: '#DFEBF4', combineTimeEvent: true, combineDescSpeaker: true },
]


const hari4 = [
  { details: "Jumat, 5 Juli 2024", time: 'SIMPOSIUM (07.00-17.20)', event: '', description: '', speaker: '', combineTimeEvent: true, combineDescSpeaker: true, merge: true, mergeCount: 38 },
  { time: '07.00-08.00', event: 'REGISTRASI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.00-08.10', event: 'PEMBUKAAN', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.10-08.20', event: 'LAGU INDONESIA RAYA & MARS PDS PATKLIN', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.20-08.25', event: 'PEMBACAAN DOA', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.25-08.35', event: 'TARIAN PENYAMBUTAN', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.35-08.45', event: 'LAPORAN KETUA PANITIA', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.45-08.55', event: 'SAMBUTAN KETUA IDI WILAYAH', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '08.55-09.05', event: 'SAMBUTAN KETUA PP PDS PATKLIN', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.05-09.15', event: 'SAMBUTAN GUBERNUR/WALIKOTA KENDARI + PEMBUKAAN ACARA', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.15-09.25', event: 'PEMBUKAAN MEDICAL EXPO & COFFEE BREAK', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.25-09.35', event: 'PRE-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '09.35-09.45', event: 'ETIK KEDOKTERAN', description: '', speaker: 'IDI Wilayah', merge: false, mergeCount: 0, skip: true },
  { time: 'PLENARY LECTURE (09.45-10.05)', event: '', description: 'LABORATORY CONTRIBUTIONS ON ADVANCING MATERNAL AND CHILD HEALTH STRATEGIES', speaker: 'DR. Dr. Teguh Triyono, M.Kes, Sp.PK, Subsp.B.D.K.T.(K)', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: 'SIMPOSIUM SESI 1', event: '', description: "THE INTEGRAL ROLE OF CLINICAL PATHOLOGISTS IN TRIPLE DISEASE ELIMINATION", speaker: 'Moderator :\ndr. Abdul Karim, SpPK', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: '10.05-10.25', event: 'SESI 1 MATERI 1', description: 'ANTENATAL STRATEGIES FOR THE TRIPLE ELIMINATION PROGRAM IN PREGNANT WOMEN', speaker: 'Prof. DR. Dr. Juminten Saimin, Sp.OG, Subsp. Obginsos', merge: false, mergeCount: 0, skip: true },
  { time: '10.25-10.45', event: 'SESI 1 MATERI 2', description: 'HEPATITIS B: ELUCIDATING PATHOGENESIS THROUGH BIOMARKERS', speaker: 'Dr. Uleng Bahrun, Sp.PK, Subsp.I.K.(K), Ph.D.', merge: false, mergeCount: 0, skip: true },
  { time: '10.45-11.05', event: 'SESI 1 MATERI 3', description: 'UNDERSTANDING DIAGNOSTICS OF SYPHILIS AND HIV TEST INTERPRETATION', speaker: 'Dr. Amarensi Milka Betaubun, M.Kes, Sp.PK, Subsp.P.I.(K)', merge: false, mergeCount: 0, skip: true },
  { time: '11.05-11.15', event: 'DISKUSI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: 'INDUSTRIAL SYMPO 1 (11.15-11.35)', event: '', description: '', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: 'ISHOMA (11.35-13.00)', event: '', description: '', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: 'SIMPOSIUM SESI 2', event: '', description: 'STRATEGIC LABORATORY MANAGEMENT FOR QUALITY IMPROVEMENT IN CLINICAL SETTINGS', speaker: 'Moderator:\nDr. Hessyani Patrisia Theodora Raranta, Sp.PK, Subsp.H.K.(K), MARS', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: '13.00-13.20', event: 'SESI 2 MATERI 1', description: 'IMPLEMENTING EFFECTIVE INFECTION PREVENTION AND CONTROL STRATEGIES IN LABORATORIES AND BLOOD TRANSFUSION UNIT', speaker: 'DR. Dr. Andaru Dahesihdewi, M.Kes, Sp.PK-K', merge: false, mergeCount: 0, skip: true },
  { time: '13.20-13.40', event: 'SESI 2 MATERI 2', description: 'MATCHING LABORATORY INFORMATION SYSTEMS TO LABORATORY NEEDS: A GUIDE FOR DIFFERENT LABORATORY TYPES', speaker: 'DR. Dr. Asvin Nurulita, M.Kes, Sp.PK, Subsp.I.K(K)', merge: false, mergeCount: 0, skip: true },
  { time: '13.40-14.00', event: 'SESI 2 MATERI 3', description: 'QUALITY AUDITS: ENHANCING PERFORMANCE IN HEALTH LABORATORY SERVICES', speaker: 'Dr. Irfan, M.Kes, Sp.PK, FISQua', merge: false, mergeCount: 0, skip: true },
  { time: '14.00-14.10', event: 'DISKUSI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: 'INDUSTRIAL SYMPO 2 (14.10-14.30)', event: '', description: '', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: 'MEET THE EXPERT', event: '', description: '', speaker: 'Moderator:\nDr. Ramla Tongko, M.Kes, Sp.PK', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: '14.30-14.40', event: 'MEET THE EXPERT MATERI 1', description: 'ENDOKRIN : INTERPRETATION OF THYROID TEST: FOCUS ON NON THYROIDAL ILLNESS', speaker: 'DR. Dr. Liong Boy Kurniawan, M.Kes, Sp.PK, Subsp. E.M (K)', merge: false, mergeCount: 0, skip: true },
  { time: '14.40-15.10', event: 'DISKUSI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '15.10-15.20', event: 'MEET THE EXPERT MATERI 2', description: 'KARDIOVASKULAR : FROM GENOMIC TO THERAPY RESISTANCE TESTING', speaker: 'DR. Dr. Astuti Giantini, Sp.PK(K), MPH', merge: false, mergeCount: 0, skip: true },
  { time: '15.20-15.50', event: 'DISKUSI', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '15.50-16.10', event: 'COFFEE BREAK & PENINJAUAN STAND MEDICAL EXPO', description: '', merge: false, mergeCount: 0, skip: true },
  { time: 'INDUSTRIAL SYMPO 3 (16.10-16.30)', event: '', description: '', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
  { time: '16.30-16.40', event: 'POST-TEST', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '16.40-16.50', event: 'DOOR PRIZE', description: '', merge: false, mergeCount: 0, skip: true },
  { time: '16.50-17.00', event: 'CLOSING CEREMONY', description: '', merge: false, mergeCount: 0, skip: true },
  { time: 'MaCLPAM AWARD + CULTURAL NIGHT (19.00-SELESAI)', event: '', description: '', merge: false, mergeCount: 0, skip: true, combineTimeEvent: true, color: '#DFEBF4' },
]

const hari5 = [
  { details: "Sabtu, 6 Juli 2024", time: 'CITY TOUR & TRIP WISATA LAINNYA', event: '', description: '', speaker: '', combineTimeEvent: true, combineDescSpeaker: true, merge: true, mergeCount: 38 },
]


const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    paddingTop: 120
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  image: {
    width: '100%',
    height: 'auto'
  },
}));

function Jadwal() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.heroContent}>
        <Container maxWidth="md" data-aos="fade-right">

        <div className="App">
          <header className="App-header">
           Hari 1 (Kamis, 4 Juli 2024) 
           <br></br><b>Workshop 1 : Hospital Blood Bank Set Up (Rg. Azalea)</b>
          </header>
          <main className="App-main">
            <ScheduleTable data={hari1}/>
          </main>
          <header className="App-header">
           <b>Workshop 2 : Integrating Routine Blood Analysis with Peripheral Blood Smear Examination: A Comprehensive Approach (Rg. Legend)</b>
          </header>
          <main className="App-main">
            <ScheduleTable data={hari2}/>
          </main>
          <header className="App-header">
           <b>Free Paper</b>
          </header>
          <main className="App-main">
            <ScheduleTable data={hari3}/>
          </main>
          <header className="App-header">
           <br></br>Hari 2 (Jumat, 5 Juli 2024) 
           <br></br><b>Simposium (Phinisi Ballroom 3)</b>
          </header>
          <main className="App-main">
            <ScheduleTable data={hari4}/>
          </main>
          <header className="App-header">
           <br></br>Hari 3 (Sabtu, 6 Juli 2024) 
           <br></br><b>City Tour & Trip Lainnya</b>
          </header>
          <main className="App-main">
            <ScheduleTable data={hari5}/>
          </main>

        </div>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default Jadwal;
