import React from 'react';
import './style.css';

const ScheduleTable = (props) => {
  function renderTextWithLineBreaks(text) {
    if (!text) return null;
    return text.split('\n').map((item, key) => (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    ));
  }

  function renderCombinedColumns(item) {
    const timeAndEvent = item.combineTimeEvent ? `${item.time} ${item.event}` : item.time;
    const descriptionAndSpeaker = item.combineDescSpeaker ? `${item.description} ${item.speaker}` : item.description;

    return (
      <React.Fragment>
        <td colSpan={item.combineTimeEvent ? 2 : 1} style={{backgroundColor: item.combineTimeEvent ? '#DFEBF4' : '#FDF0D1', textAlign: 'center'}}>{timeAndEvent}</td>
        {!item.combineTimeEvent && <td>{item.event}</td>}
        <td colSpan={item.combineDescSpeaker ? 2 : 1} style={{backgroundColor: item.combineTimeEvent ? '#DFEBF4' : '#FDF0D1', textAlign: 'center'}}>{descriptionAndSpeaker}</td>
        {!item.combineDescSpeaker && <td>{renderTextWithLineBreaks(item.speaker)}</td>}
      </React.Fragment>
    );
  }

  function renderMergedCells(item, index) {
    if (item.merge) {
      return <td rowSpan={item.mergeCount} style={{textAlign: 'center'}}>{item.details}</td>;
    }
    if (item.skip) {
      return null;
    }
    return <td>{item.details}</td>;
  }

  return (
    <div className="schedule-table-container">
      <table className="schedule-table">
        <thead>
          <tr>
            <th>Hari/Tgl</th>
            <th>Waktu (WITA)</th>
            <th>Acara</th>
            <th>Judul</th>
            <th>PIC</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={index} style={{ backgroundColor: item.color ? item.color : '#FDF0D1' }}>
              {renderMergedCells(item, index)}
              {renderCombinedColumns(item)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleTable;
