import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import YouTube from 'react-youtube';

// import Carousel from '../components/Carousel';

const useStyles = makeStyles((theme) => ({
  heroContentBackground: {
    backgroundImage: `url(${require("../assets/background.jpeg")})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    padding: theme.spacing(4, 0, 6),
  },
  youtubeVideo: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 Aspect Ratio
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  pulsate: {
    width: '80%',
    height: 'auto',
    animation: `$pulsate 1.5s infinite`,
  },
  "@keyframes pulsate": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.05)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}));

export default function HomePage() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  const opts = {
    playerVars: {
      autoplay: 1,
      mute: 0,
    },
  };
  
  const onReady = (event) => {
    event.target.playVideo();
  };

  return (
    <React.Fragment>
      <div className={classes.heroContentBackground}>
        <Container maxWidth="md" style={{paddingTop: 120, paddingBottom: 80}}>
          <Grid container spacing={4} alignItems="center" data-aos="fade-right">
            <Grid item xs={12} md={12} style={{justifyContent: 'center', alignItems: 'center'}}>
              <Typography component="h1" variant="h3" align="left" style={{ color: '#D26A31', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} gutterBottom>
                8th MaCPLAM
              </Typography>
              <Typography component="h1" variant="h4" align="left" style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} gutterBottom>
                TOWARDS A HEALTHIER INDONESIA: EMPOWERING ROLE OF CLINICAL PATHOLOGISTS IN NATIONAL HEALTHCARE
              </Typography>

              <Typography variant="h5" align="left" style={{ color: '#191970', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }}>
                4-6 Juli 2024
              </Typography>
              <Typography variant="h5" align="left" style={{ color: '#191970', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} paragraph>
              Claro Hotel, Kendari
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="md" style={{ paddingTop: 100, paddingBottom: 80 }}>
        <Grid container spacing={4} alignItems="center" data-aos="fade-right">
          <div className={classes.youtubeVideo}>
            <YouTube videoId="-CpIurM3EfU" opts={opts} onReady={onReady} />
          </div>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
